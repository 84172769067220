@import './vars.scss';

// ****************************************
//   Main Styles
// ****************************************
html {
  padding: 0;
}

// ****************************************
//   Resets
// ****************************************
body {
  margin: 0;
  font-family: 'proximanova-regular';
}