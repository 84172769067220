.animation:hover{
    animation-name: rainbow;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}
@keyframes rainbow {
    0%{
        box-shadow:-5px 3px 5px red;
    }
    25%{
        box-shadow:0px -5px 5px green;
    }
    50%{
        box-shadow:5px 0px 5px yellow;
    }
    75%{
        box-shadow:0px 5px 5px blue;
    }
    100%{
        box-shadow:-5px 0px 5px pink;
    } 
/* 
    from{
        box-shadow:0px 0px 10px red;
    }
    to{
        box-shadow:0px 0px 10px yellow;
    } */
}
