.Container{
    
    /* //width: 1450px; */
    width: 100%;
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
    margin: 0 auto;

}
 
.Content{
    width: 100%;
    color: white;
    text-align: center;
}
 
.Flipped, .Flipped .Content{
    transform: rotateX(180deg);
}
