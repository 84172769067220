.draggableBlock {
  padding: 2px;
  width: 320px;
  cursor: move;
  color: wheat;
  text-align: left;
  cursor: move;
  transform: translate3d(300px, 80px, 0px);
  will-change: transform;
  /* opacity: 0.9; */
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .draggableBlock {
    padding: 6px;
    background: rgb(40, 75, 82);
    width: 140px;
    cursor: move;
    color: wheat;
    text-align: left;
    cursor: move;
    transform: translate3d(300px, 80px, 0px);
    will-change: transform;
    /* opacity: 0.9; */
  }
}

.draggableBlock1 {
  background: "#ccc";
  width: "200px";
  padding: "10px";
  cursor: "move";
}

/* .MuiAccordion-root {
  min-height: "10px" !important;
  max-height: "10px" !important;
} */
.box {
 
  /* box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%); */
  /* opacity: 0.9; */
}
#dragMenu {
  min-width: 100px;
  padding: 1px;
}

.dragMenu {
  min-width: 120px;
}

.my-menuitem {
  min-width: 120px;
  background: #374850;
  padding: 1px;
  color: white;
  /* box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);  */
}

.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.095rem 0.3rem !important;
}

/* ::-webkit-scrollbar {
  width: 7px;
  height: 8px !important;
  cursor: pointer;
} */

/* ::-webkit-scrollbar-thumb {
  background: #7a8686;
  border-radius: 10px;
  width: 18px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(13, 54, 189, 0.699);
  cursor: pointer;
} */

.treeLineview {
  position: "relative";
  margin-left: "16px";
  padding-left: "18px";
  border-left: "1px solid gray";
}

.treeLineview::before {
  pointer-events: "none";
  content: "";
  position: "absolute";
  width: 32;
  left: -16;
  top: 12;
  border-bottom: "1px solid gray";
}

.divStyleAcrd1 {
  border-left: "2px solid red";
  height: "170px";
  font-weight: "400px";
}

.listdataLine {
  background-color: #28282b;
  width: 1px;
  height: 100%;
  top: 16px;
  left: 16px;
  position: relative;
  padding-top: 20px;
}

.listdataLine1 {
  background-color: #28282b;
  width: 1px;
  height: 100%;
  top: 16px;
  left: 8px;
  position: relative;
  padding-top: 15px;
}

.listdataLine2 {
  background-color: #28282b;
  width: 1px;
  top: 14px;
  left: 10px;
  position: relative;
}

.listdataLine3 {
  background-color: black;
  width: 1px;
  height: 16px;
  top: 13px;
  left: 18px;
  position: relative;
}

.test .x-accordion-hd-over {
  background-color: yellow;
}

.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  /* padding: 0.195rem 0.03rem 0 0.001rem; */
  padding-left: "0.009rem !important";
  max-height: 20px;
}

.my-menuitem-hover {
  color: white !important;
  background-color: black !important;
}
.my-menuitem-hover1 {
}

.szh-menu__item--hover {
  color: white !important;
  background-color: black !important;
}
/*
.my-menuitem{
  color: black;
  background-color: white;
}
*/
